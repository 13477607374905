import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import mattressIpsum from 'mattress-ipsum';
import { Modal, Button } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "modal"
    }}>{`Modal`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Modal from 'mfcl/Modal'
`}</code></pre>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Modal} mdxType="Props" />
    <p>{`NOTE: DO NOT add "Component" as a wrapper. That is only for documentation. Use React state/context to manage open/close`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Component initialState={{ isOpen: false }}>\n  {({ setState, state }) => {\n    return (\n      <div>\n        <Modal\n          header=\"Basic Modal\"\n          subheader=\"Subheader Details\"\n          isOpen={state.isOpen}\n          onClose={isOpen => setState({ isOpen: false })}\n        >\n          {mattressIpsum(23)}\n          <br />\n          <br />\n          <Button onClick={() => setState({ isOpen: false })}>\n            Close Modal\n          </Button>\n        </Modal>\n        <Button\n          btnType=\"primary\"\n          onClick={() => setState({ isOpen: !state.isOpen })}\n          size=\"lg\"\n        >\n          Open Modal\n        </Button>\n      </div>\n    )\n  }}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Modal,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        isOpen: false
      }} mdxType="Component">
    {({
          setState,
          state
        }) => {
          return <div>
          <Modal header="Basic Modal" subheader="Subheader Details" isOpen={state.isOpen} onClose={isOpen => setState({
              isOpen: false
            })} mdxType="Modal">
            {mattressIpsum(23)}
            <br />
            <br />
            <Button onClick={() => setState({
                isOpen: false
              })} mdxType="Button">Close Modal</Button>
          </Modal>
          <Button btnType="primary" onClick={() => setState({
              isOpen: !state.isOpen
            })} size="lg" mdxType="Button">
            Open Modal
          </Button>
        </div>;
        }}
  </Component>
    </Playground>
    <h2 {...{
      "id": "nested-usage"
    }}>{`Nested Usage`}</h2>
    <Playground __position={2} __code={'<Component\n  initialState={{\n    isOpen: false,\n    isNestedOpen: false,\n    isNestedDrawerOpen: false,\n  }}\n>\n  {({ setState, state }) => {\n    return (\n      <>\n        <Modal\n          header=\"Parent Modal\"\n          isOpen={state.isOpen}\n          onClose={isOpen => setState({ isOpen: false })}\n        >\n          <Modal\n            header=\"Nested Modal\"\n            isOpen={state.isNestedOpen}\n            onClose={isNestedOpen => setState({ isNestedOpen: false })}\n          >\n            <Button\n              btnType=\"link\"\n              onClick={() => setState({ isNestedOpen: false })}\n            >\n              Close Nested Modal\n            </Button>\n          </Modal>\n          <Button onClick={() => setState({ isOpen: false })}>\n            Close Parent Modal\n          </Button>\n          <Button\n            btnType=\"link\"\n            onClick={() => setState({ isNestedOpen: !state.isNestedOpen })}\n            size=\"lg\"\n          >\n            Open Nested Modal\n          </Button>\n        </Modal>\n        <Button\n          btnType=\"primary\"\n          onClick={() => setState({ isOpen: !state.isOpen })}\n          size=\"lg\"\n        >\n          Open Parent Modal\n        </Button>\n      </>\n    )\n  }}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Modal,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        isOpen: false,
        isNestedOpen: false,
        isNestedDrawerOpen: false
      }} mdxType="Component">
    {({
          setState,
          state
        }) => {
          return <>
          <Modal header="Parent Modal" isOpen={state.isOpen} onClose={isOpen => setState({
              isOpen: false
            })} mdxType="Modal">
            <Modal header="Nested Modal" isOpen={state.isNestedOpen} onClose={isNestedOpen => setState({
                isNestedOpen: false
              })} mdxType="Modal">
              <Button btnType="link" onClick={() => setState({
                  isNestedOpen: false
                })} mdxType="Button">Close Nested Modal</Button>
            </Modal>
            <Button onClick={() => setState({
                isOpen: false
              })} mdxType="Button">Close Parent Modal</Button>
            <Button btnType="link" onClick={() => setState({
                isNestedOpen: !state.isNestedOpen
              })} size="lg" mdxType="Button">
              Open Nested Modal
            </Button>
          </Modal>
          <Button btnType="primary" onClick={() => setState({
              isOpen: !state.isOpen
            })} size="lg" mdxType="Button">
            Open Parent Modal
          </Button>
        </>;
        }}
  </Component>
    </Playground>
    <h2 {...{
      "id": "custom-usage"
    }}>{`Custom Usage`}</h2>
    <Playground __position={3} __code={'<Component initialState={{ isOpen: false }}>\n  {({ setState, state }) => {\n    return (\n      <div>\n        <Modal\n          header={<div>Basic Modal (Element)</div>}\n          subheader={\n            <div style={{ marginBottom: \'15px\' }}>\n              Subheader Details (Element)\n            </div>\n          }\n          isOpen={state.isOpen}\n          borderStyle=\"square\"\n          onClose={isOpen => setState({ isOpen: false })}\n        >\n          {mattressIpsum(23)}\n          <br />\n          <br />\n          <Button onClick={() => setState({ isOpen: false })}>\n            Close Modal\n          </Button>\n        </Modal>\n        <Button\n          btnType=\"primary\"\n          onClick={() => setState({ isOpen: !state.isOpen })}\n          size=\"lg\"\n        >\n          Open Modal\n        </Button>\n      </div>\n    )\n  }}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Modal,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        isOpen: false
      }} mdxType="Component">
    {({
          setState,
          state
        }) => {
          return <div>
          <Modal header={<div>Basic Modal (Element)</div>} subheader={<div style={{
              marginBottom: '15px'
            }}>Subheader Details (Element)</div>} isOpen={state.isOpen} borderStyle="square" onClose={isOpen => setState({
              isOpen: false
            })} mdxType="Modal">
            {mattressIpsum(23)}
            <br />
            <br />
            <Button onClick={() => setState({
                isOpen: false
              })} mdxType="Button">Close Modal</Button>
          </Modal>
          <Button btnType="primary" onClick={() => setState({
              isOpen: !state.isOpen
            })} size="lg" mdxType="Button">
            Open Modal
          </Button>
        </div>;
        }}
  </Component>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      